<template>
  <div>
    <page-description class="bar-description"
      :path="[
        { label: $t('data.sidebar_mes_occupations'), route: 'occupations' },
        { label: $t('data.locations_mes_habitants'), route: 'habitants' },
      ]"
    />
    <Habitants v-if="path == ''" />
    <router-view v-else />
  </div>
</template>

<script>
import Habitants from "../gestion-immobiliere/habitants/Habitants.vue";
export default {
  components: {
    Habitants,
  },
  data: () => ({
    path: "",
  }),
  created() {
    this.getPath();
  },
  mounted() {
    this.getPath();
  },
  updated() {
    this.getPath();
  },
  methods: {
    getPath() {
      this.path = this.$route.path.replace("/gestion-locative", "");
    },
  },
};
</script>

<style>
  
</style>
